import React from 'react'
import { graphql } from 'gatsby'
import { Container } from 'theme-ui'
import Layout from '@solid-ui-layout/Layout'
import Seo from '@solid-ui-components/Seo'
import Divider from '@solid-ui-components/Divider'
import ModalWithTabs from '@solid-ui-blocks/Modal/Block01'
import ModalSimple from '@solid-ui-blocks/Modal/Block02'
import Header from '@solid-ui-blocks/Header/Block01'
import Hero from '@solid-ui-blocks/Hero/Block02'
import Features from '@solid-ui-blocks/Features/Block06'
import FeatureTabOne from '@solid-ui-blocks/FeaturesWithPhoto/Block05'
import FeatureTabTwo from '@solid-ui-blocks/FeaturesWithPhoto/Block06'
import FeatureTabThree from '@solid-ui-blocks/FeaturesWithPhoto/Block01'
import Pricing from '@solid-ui-blocks/Pricing/Block01'
import WhyChooseUs from '@solid-ui-blocks/Features/Block04'
import GetStarted from '@solid-ui-blocks/Stats/Block01'
import Testimonials from '@solid-ui-blocks/Testimonials/Block01'
import Companies from '@solid-ui-blocks/Companies/Block01'
import Blog from '@solid-ui-blocks/Blog/Block01'
import Footer from '@solid-ui-blocks/Footer/Block01'
import { normalizeBlockContentNodes } from '@blocks-helpers'
import theme from './_theme'
import styles from './_styles'

const IndexPage = props => {
  const { allBlockContent } = props.data
  const content = normalizeBlockContentNodes(allBlockContent?.nodes)

  return (
    <Layout theme={theme} {...props}>
      <Seo
          title='Przez zaangażowanie do celu'
          description={"Udostępnij pracownikom anonimowy feedback w celu poprawy działania firmy! Pozwól członkom zespołu na budowanie organizacji i sprawdź jak zmieni się ich zaangażowania przy zastosowaniu metodologii PULSU. Wypróbuj aplikację za darmo!"}
          keywords={["zaangażowanie", "zespół", "współpraca", "optymalizacja hr", "zatrudnienie", "poprawa", "manager", "rozmowa", "feedback pracownika", "anonimowy", "feedback", "badanie", "ankieta", "działanie", "problem", "skuteczność", "okr", "badanie zaangażowania pracowników", "darmowa aplikacja"]}
      />

      {/* Modals */}
      <ModalWithTabs content={content['authentication']} reverse />
      <ModalWithTabs content={content['contact']} />
      <ModalSimple content={content['advertisement']} />
      {/* e/o Modals */}

      {/* Blocks */}
      <Header content={content['header']} />

      <Divider space='4' />
      <Divider space='3' />

      <Container variant='full' sx={styles.heroContainer}>
          <Hero content={content['hero']} />
      </Container>

      <Divider space='5' />
      {/*
      <Companies content={content['companies']} />
      <Divider space='5' />
      */}
      <Features content={content['features']} />
      <Divider space='5' />
      <Container variant='wide' sx={styles.featuresContainer}>
        <FeatureTabOne content={content['feature-tab-one']} reverse />
        <Divider space='5' />
        <Divider space='5' />
        <FeatureTabThree content={content['feature-tab-three']} />
      </Container>
      <Divider space='5' />
      <Divider space='5' />
      <WhyChooseUs content={content['why-choose-us']} />
      <Divider space='5' />
      {/*
      <Divider space='5' />
      <Pricing content={content['pricing']} />
      <Divider space='5' />
      <Divider space='5' />
      <Container variant='full' sx={styles.testimonialsContainer}>
        <Testimonials content={content['testimonials']} />
      </Container>
      */}
      <GetStarted content={content['get-started']} />
      <Divider space='5' />
      {/*
      <Divider space='5' />
      <Blog content={content['latest-blogs']} />
      <Divider space='5' />
      <Divider space='5' />
      */}
      <Footer content={content['footer']} />
      {/* e/o Blocks */}
    </Layout>
  )
}

export const query = graphql`
  query indexBlockContent {
    allBlockContent(filter: { page: { in: ["homepage", "shared"] } }) {
      nodes {
        ...BlockContent
      }
    }
  }
`
export default IndexPage
